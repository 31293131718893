<template>
    <div class="cashbackPopup">
        <div class="popupContent">
            <div class='closeImg' @click="closePopup">
                <img src="@/assets/htui/close.png" alt="">
            </div>
            <!-- 推广返现 -->
            <div class="ptitle">
                <div></div>
                <div>
                    <div>推广返现</div>
                    <div>可获得高额返现邀新福利</div>
                </div>
                <div></div>
            </div>

            <div class="copyContent">复制下方链接，邀请用户注册，即可获得高额返现福利 <span class="tips" v-if="!isLogin"> （登录后有效， <span
                        style='color:#02A7F0;cursor: pointer;' @click="goLogin">立即登录</span> ）</span>
            </div>

            <div class="copyLink">
                <span>{{ InviteCodeUrl }}</span>
                <span class="copy" :data-clipboard-text="InviteCodeUrl" @click="copyCode">立即复制</span>
            </div>

            <div class="tops">邀请成功后，两级返现机制可让您获得下级用户<span class="linght">消费金额
                    {{ firstLevelRatio && secondLevelRatio ? parseInt((firstLevelRatio + secondLevelRatio) * 100) : 15
                    }}
                    %的返点收益</span> ，返现机制详情可前往
                <span class="selfHome" @click="goUserDetail">个人中心</span> 查看。
            </div>
            <div class="yhd">
                <div>他们都已获得高额返点</div>
                <div>
                    <vue-seamless-scroll :data="listData" :class-option="classOption" class="warp">
                        <ul class="ul-item">
                            <li class="li-item" v-for="(item, index) in listData" :key="index">
                                <img src="@/assets/htui/userimg.png" v-if="!item.avatar"
                                    style="width: 100%;height: 100%;">
                                <img :src="`${baseUrl + item.avatar}`" v-else style="width: 100%;height: 100%;" alt="">
                            </li>
                        </ul>
                    </vue-seamless-scroll>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import { getUserInfo, getConfigKey } from '@/api/index'
import Clipboard from 'clipboard';
import { getToken } from '@/utils/auth';
export default {
    data() {
        return {
            listData: [
                {
                    name: '',
                    avatar: ''
                },
                {
                    name: '',
                    avatar: '/static/img/avatarFic/h1.jpg'
                },
                {
                    name: '',
                    avatar: '/static/img/avatarFic/h2.jpg'
                },
                {
                    name: '',
                    avatar: ''
                },
                {
                    name: '',
                    avatar: '/static/img/avatarFic/h3.jpg'
                },
                {
                    name: '',
                    avatar: ''
                },
                {
                    name: '',
                    avatar: '/static/img/avatarFic/h5.jpg'
                },
                {
                    name: '',
                    avatar: '/static/img/avatarFic/h4.jpg'
                },
                {
                    name: '',
                    avatar: ''
                },
                {
                    name: '',
                    avatar: ''
                },
            ],
            classOption: {
                limitMoveNum: 2,
                direction: 3,
                step: 0.3,
                hoverStop: false
            },
            baseUrl: '',
            InviteCodeUrl: '',
            selfInviteCode: '',
            isLogin: false,
            firstLevelRatio: '',
            secondLevelRatio: ''
        };
    },
    components: {
        vueSeamlessScroll
    },

    mounted() {
        this.getConf();
        this.baseUrl = window.location.protocol + '//' + window.location.hostname + '/';
        if (getToken()) {
            this.isLogin = true;
            this.getUserData()
        } else {
            this.isLogin = false;
            this.InviteCodeUrl = this.baseUrl + 'index'
        }
    },

    methods: {
        //获取个人信息
        getUserData() {
            getUserInfo().then((res) => {
                this.selfInviteCode = res.data.selfInviteCode;
                this.InviteCodeUrl = `${this.baseUrl}index?inviteCode=${this.selfInviteCode}`
            })
        },
        //复制
        copyCode() {
            let clipboard = new Clipboard('.copy')
            clipboard.on('success', e => {
                this.$message({
                    message: "复制成功",
                    type: "success",
                    customClass: 'messageIndex'
                })
                // 释放内存
                clipboard.destroy()
            })
        },
        //关闭弹框
        closePopup() {
            this.$parent.showCashbackPopup = false;
        },
        //前往个人中心
        goUserDetail() {
            if (this.isLogin) {
                this.$parent.showCashbackPopup = false;
                this.$router.push('/personalCenter');
            } else {
                this.$parent.showCashbackPopup = false;
                this.$parent.showLoginPopup = true;
            }
        },
        //立即登录
        goLogin() {
            this.$parent.showCashbackPopup = false;
            this.$parent.showLoginPopup = true;
        },
        //获取返现比例
        getConf() {
            getConfigKey('first_level_ratio').then((res) => {
                this.firstLevelRatio = Number(res.msg)
            })
            getConfigKey('second_level_ratio').then((res) => {
                this.secondLevelRatio = Number(res.msg)
            })
        },
    },
};
</script>


<style lang="scss">
.cashbackPopup {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999999999 !important;

    @keyframes scaleShow {
        0% {
            transform: scale(0);
        }

        100% {
            transform: scale(1);
        }
    }

    .popupContent {
        background: white;
        position: relative;
        width: 1000px;
        height: 650px;
        padding: 0 60px;
        padding-top: 20px;
        border-radius: 10px;
        box-sizing: border-box;
        animation: scaleShow 0.6s ease forwards;

        .closeImg {
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;

            img {
                width: 40px;
                height: 40px;
            }
        }

        .ptitle {
            height: 110px;
            display: flex;
            align-items: center;

            >div:nth-child(1) {
                height: 2px;
                width: 240px;
                background-color: #000000;
            }

            >div:nth-child(2) {
                width: 400px;
                color: #000000;
                font-family: "微软雅黑";

                >div:nth-child(1) {
                    font-size: 44px;
                    margin-bottom: 6px;
                }

                >div:nth-child(2) {
                    font-size: 32px;
                }
            }

            >div:nth-child(3) {
                height: 2px;
                width: 240px;
                background-color: #000000;
            }
        }

        .copyContent {
            text-align: left;
            font-size: 18px;
            margin: 50px 0 40px 0;
            color: #000000;

            .tips {
                font-size: 14px;
                color: #2c3e50;
            }
        }

        .copyLink {
            text-align: left;
            margin-bottom: 40px;

            >span:nth-child(1) {
                font-size: 18px
            }

            >span:nth-child(2) {
                font-size: 22px;
                margin-left: 40px;
                color: #02A7F0;
                cursor: pointer;
            }
        }

        .tops {
            text-align: left;
            color: #A03E1B;
            font-size: 18px;
            line-height: 30px;

            .linght {
                color: #D9001B;
                font-size: 28px;
            }

            .selfHome {
                color: #02A7F0;
                cursor: pointer;
            }

        }

        .yhd {
            position: absolute;
            bottom: 30px;
            width: 880px;

            >div:nth-child(1) {
                width: 100%;
                text-align: center;
                font-size: 16px;
                color: #D9001B;
                font-weight: bold;
                margin-bottom: 12px;

            }

            >div:nth-child(2) {
                width: 100%;
                height: 90px;
                background-color: white;
                border-radius: 50px;
                overflow: hidden;
                box-shadow: 0 2px 10px 0 rgb(171 171 171);

                .warp {
                    width: 100%;
                    height: 90px;
                    margin: 0 auto;
                    overflow: hidden;

                    ul {
                        list-style: none;
                        padding: 0;
                        margin: 0 auto;

                        &.ul-item {
                            display: flex;

                            .li-item {
                                width: 70px;
                                height: 70px;
                                margin-right: 28px;
                                line-height: 120px;
                                border: 1px solid #d9d9d9;
                                border-radius: 50%;
                                color: #fff;
                                text-align: center;
                                font-size: 30px;
                                margin-top: 10px;
                                overflow: hidden;
                            }
                        }
                    }
                }
            }

        }
    }
}

.messageIndex {
    z-index: 9999999999 !important;
}
</style>